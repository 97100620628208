export default {
	page_title: 'Аккаунт',
	postal_address: 'Данные доставки и контакты',
	'postal_address.form.success': 'Данные сохранены',
	'postal_address.form.error.empty': 'Поле «{field}» не заполнено',
	'postal_address.form.error.wrong_format': 'Поле «{field}» не соотвутствует формату «{format}»',
	'postal_address.field.label.contacts_name_first': 'Имя',
	'postal_address.field.placeholder.contacts_name_first': 'Иван',
	'postal_address.field.label.contacts_name_second': 'Фамилия',
	'postal_address.field.placeholder.contacts_name_second': 'Иванов',
	'postal_address.field.label.contacts_name_fathers': 'Отчество',
	'postal_address.field.placeholder.contacts_name_fathers': 'Иванович',
	'postal_address.field.label.contacts_address_country': 'Страна',
	'postal_address.field.placeholder.contacts_address_country': 'Российская Федерация',
	'postal_address.field.label.contacts_address_index': 'Почтовый индекс',
	'postal_address.field.placeholder.contacts_address_index': '123456',
	'postal_address.field.label.contacts_address_city': 'Город',
	'postal_address.field.placeholder.contacts_address_city': 'Москва',
	'postal_address.field.label.contacts_address_value': 'Улица, дом, квартира',
	'postal_address.field.placeholder.contacts_address_value': 'ул. Пушкина, д. 1, корп. 2, кв. 3',
	'postal_address.field.label.contacts_phone': 'Номер телефона',
	'postal_address.field.placeholder.contacts_phone': '+71234567890',
	'postal_address.field.label.contacts_email': 'Электронная почта',
	'postal_address.field.placeholder.contacts_email': 'ivanov@mail.com',
	'postal_address.field.label.contacts_telegram': 'Телеграм',
	'postal_address.field.placeholder.contacts_telegram': '@ivanov',
	'postal_address.action.save': 'Сохранить',
	social_networks: 'Социальные сети',
	'social_network.twitch': 'Twitch',
	'social_network.google': 'YouTube',
	'social_network.telegram': 'Telegram',
	'social_network.steam': 'Steam',
	'social_network.winline': 'Winline',
	'social_network.paripass': 'PariPass',
	'social_network.action.connect': 'Привязать {network}',
	'social_network.action.connected': 'Успешо привязан',
};
