import * as React from 'react';

import _isNil from 'lodash/isNil';

import { ClassName } from 'src/lib';

import type { App } from 'src/app';

import { Page, PageHead, PageBody, Heading, TextBlock, Throbber } from 'src/components';

export interface IRouteFaqPropTypes {
	app: App;
}

const cn = ClassName.create('route-special-page', require('./style.less'));

export function RouteSpecialPage(props: IRouteFaqPropTypes): React.ReactNode {
	const { leaderboard } = props.app.models;

	const title = leaderboard.currentLeaderboard?.specialPage.title || leaderboard.currentLeaderboard?.title || '';
	const content = leaderboard.currentLeaderboard?.specialPage.content || null;

	return (
		<Page>
			<PageHead title={title} />
			<PageBody>
				<div className={cn.get()}>
					<Heading>{title}</Heading>
					{!_isNil(content) ? <TextBlock content={content} /> : <Throbber />}
				</div>
			</PageBody>
		</Page>
	);
}
