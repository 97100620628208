export default {
	page_title: 'Account',
	postal_address: 'Postal address and contacts',
	'postal_address.form.success': 'Data saved',
	'postal_address.form.error.empty': 'Field "{field}" must not be empty',
	'postal_address.form.error.wrong_format': 'Field "{field}" must fit format "{format}"',
	'postal_address.field.label.contacts_name_first': 'Name',
	'postal_address.field.placeholder.contacts_name_first': 'Ivan',
	'postal_address.field.label.contacts_name_second': 'Surname',
	'postal_address.field.placeholder.contacts_name_second': 'Ivanov',
	'postal_address.field.label.contacts_name_fathers': "Father's name",
	'postal_address.field.placeholder.contacts_name_fathers': '',
	'postal_address.field.label.contacts_address_country': 'Country',
	'postal_address.field.placeholder.contacts_address_country': 'Russian Federation',
	'postal_address.field.label.contacts_address_index': 'ZIP Index',
	'postal_address.field.placeholder.contacts_address_index': '123456',
	'postal_address.field.label.contacts_address_city': 'City',
	'postal_address.field.placeholder.contacts_address_city': 'Moscow',
	'postal_address.field.label.contacts_address_value': 'Street, house, apartment',
	'postal_address.field.placeholder.contacts_address_value': 'Pushkin str., 1-2, apt. 3',
	'postal_address.field.label.contacts_phone': 'Phone number',
	'postal_address.field.placeholder.contacts_phone': '+71234567890',
	'postal_address.field.label.contacts_email': 'E-mail',
	'postal_address.field.placeholder.contacts_email': 'ivanov@mail.com',
	'postal_address.field.label.contacts_telegram': 'Telegram',
	'postal_address.field.placeholder.contacts_telegram': '@ivanov',
	'postal_address.action.save': 'Save',
	social_networks: 'Social networks',
	'social_network.twitch': 'Twitch',
	'social_network.google': 'YouTube',
	'social_network.telegram': 'Telegram',
	'social_network.steam': 'Steam',
	'social_network.winline': 'Winline',
	'social_network.paripass': 'PariPass',
	'social_network.action.connect': 'Connect {network}',
	'social_network.action.connected': 'Successfull connection',
};
