export const ROOT = '/';

export const LEADERBOARD = '/leaderboard';
export const LEADERBOARD_TABLE = '/leaderboard/:organizationId?/:resourceId?';
export const LEADERBOARD_SCHEDULE = '/leaderboard/:organizationId?/:resourceId?/schedule';

export const PROFILE = '/profile';
export const PROFILE_ACTIVE_SEASON = '/profile/:organizationId?/:resourceId?';
export const PROFILE_HISTORY = '/profile/:organizationId?/:resourceId?/history';
export const PROFILE_ACCOUNT = '/profile/:organizationId?/:resourceId?/account';

export const FAQ = '/faq';
export const RULES = '/rules';

export const SPECIAL_PAGE = '/special';

export const WALL = '/wall';
export const WALL_AUTH = '/wall/auth';
